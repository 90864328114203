import { RouteName } from "@/enums/RouteName";
import { nextTick, ref } from "vue";
import { useRouter } from "vue-router";

export default () => {
  const router = useRouter();
  const viewMore = ref(false);
  const minCountToShowMoreButton = 2;
  const minCountToViewAllButton = 4;

  const getViewButtonContent = (count: number, showMore: boolean) => {
    if (count > minCountToShowMoreButton && !showMore) {
      return "View more";
    } else if (count > minCountToViewAllButton) {
      return "View all";
    } else {
      return "";
    }
  };
  const onClickViewAllButton = (name: RouteName) => {
    router.push({ name });
    nextTick(() => {
      viewMore.value = false;
    });
  };

  const onClickViewMoreButton = () => {
    viewMore.value = true;
  };
  return {
    viewMore,
    getViewButtonContent,
    onClickViewAllButton,
    onClickViewMoreButton,
  };
};
