import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-058c840c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "welcome-section-container" }
const _hoisted_2 = { class: "welcome-message" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, "Welcome back, " + _toDisplayString($setup.user.firstName), 1),
    _createVNode($setup["NButton"], {
      class: "new-quote-button small-button",
      round: "",
      type: "info",
      onClick: $setup.onNewRequestForQuoteClicked
    }, {
      default: _withCtx(() => [
        _createTextVNode("New Quote Request")
      ]),
      _: 1
    })
  ]))
}