import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-9c897736"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = { class: "order-quick-filter-item--title" }
const _hoisted_3 = { class: "order-quick-filter-item--count" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass(["order-quick-filter-item", {'order-quick-filter-item-active': $props.active}]),
    disabled: !$props.count && !$props.active
  }, [
    _createElementVNode("span", _hoisted_2, _toDisplayString($props.title), 1),
    _createElementVNode("span", _hoisted_3, _toDisplayString($props.count || 0), 1)
  ], 10, _hoisted_1))
}